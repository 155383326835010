<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            type="process"
            label="단위공정"
            name="subProcessCd"
            v-model="searchParam.subProcessCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.revisionNum + ' / ' +  props.row.sopNo}}
                  </div>
                  <div class="txt-box-grid text-positive-box">
                    {{ props.row.processName}}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.sopName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.hazardGradeName + ', ' + props.row.workTypeName }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="bg-grey-2 text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'sop-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        keyword: '',
        useFlag: 'Y',
        subProcessCd: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'sopNo',
            field: 'sopNo',
            label: '안전작업표준 관리번호',
            align: 'center',
            sortable: true,
            style: 'width: 160px',
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '안전작업표준 작업명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'hazardGradeName',
            field: 'hazardGradeName',
            label: '위험등급',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workTypeName',
            field: 'workTypeName',
            label: '작업유형',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workKindName',
            field: 'workKindName',
            label: '작업형태',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workClassName',
            field: 'workClassName',
            label: '작업구분',
            align: 'center',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width: 70px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      if (this.$route.query.mdmSopId) {
        this.linkClick('', {mdmSopId: this.$route.query.mdmSopId});
      }
      this.listUrl = selectConfig.mdm.sop.job.list.url;

      this.getList()
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(evt, row) {
      this.popupOptions.title = '안전작업표준 상세'; // 작업허가서 상세
      this.popupOptions.param = {
        mdmSopId: row ? row.mdmSopId : '',
      };
      this.popupOptions.target = () => import(`${'./sopDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
